import React, { DetailedHTMLProps, PropsWithChildren } from 'react';

import loader from '../../../../../assets/svg/loader.svg';
import { Options } from 'react-select';
import { ISelectOption } from '../../../../../Components/Selector/ReactSelector/ReactSelector';
import cn from 'classnames';

interface ISelectorWrapperProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  options?: Options<ISelectOption>;
  label: string;
}

export const SelectorWrapper: React.FC<
  PropsWithChildren<ISelectorWrapperProps>
> = ({ options, children, label, className, ...rest }) => {
  return (
    <div
      className={cn(
        'sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5',
        className,
      )}
      {...rest}
    >
      <label className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
        {label}
      </label>
      <div>
        {options?.length ? (
          <>{children}</>
        ) : (
          <img className='h-10' alt={'loader'} src={loader} />
        )}
      </div>
    </div>
  );
};
