import { createSlice } from '@reduxjs/toolkit';
import { appActions } from './appActions';
import { AppRootStateType } from '../../store';
import { AppAsyncActions } from './appAsyncActions';

type InitStateType = {
  error: string | null;
  successMessage: string | null;
  isLoading: boolean;
  isShowErrorToast: boolean;
  isShowSuccessToast: boolean;
  isAppInitialized: 'idle' | 'inProgress' | 'success' | 'failure';
};

const initialState: InitStateType = {
  error: null,
  successMessage: null,
  isLoading: false,
  isShowErrorToast: false,
  isShowSuccessToast: false,
  isAppInitialized: 'idle',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(appActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
      })
      .addCase(appActions.setError, (state, action) => {
        state.error = action.payload.error;
      })
      .addCase(appActions.setShowErrorToast, (state, action) => {
        state.isShowErrorToast = action.payload;
      })
      .addCase(appActions.setShowSuccessToast, (state, action) => {
        state.isShowSuccessToast = action.payload;
      })
      .addCase(appActions.setSuccessMessage, (state, action) => {
        state.successMessage = action.payload.message;
      })
      .addCase(AppAsyncActions.initializeApp.pending, (state) => {
        state.isAppInitialized = 'inProgress';
      })
      .addCase(AppAsyncActions.initializeApp.fulfilled, (state) => {
        state.isAppInitialized = 'success';
      })
      .addCase(AppAsyncActions.initializeApp.rejected, (state) => {
        state.isAppInitialized = 'failure';
      });
  },
});
export const selectIsLoading = (state: AppRootStateType) => state.app.isLoading;
export const selectIsShowErrorToast = (state: AppRootStateType) =>
  state.app.isShowErrorToast;
export const selectError = (state: AppRootStateType) => state.app.error;
export const selectIsShowSuccessToast = (state: AppRootStateType) =>
  state.app.isShowSuccessToast;
export const selectSuccessMessage = (state: AppRootStateType) =>
  state.app.successMessage;
export const selectIsAppInitialized = (state: AppRootStateType) =>
  state.app.isAppInitialized;
