import React, { ChangeEvent, DetailedHTMLProps, memo } from 'react';

interface ISliderProps
  extends DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onChangeRange?: (value: number) => void;
}

export const Slider: React.FC<ISliderProps> = memo(
  ({ onChange, onChangeRange, value, max, min = 0, ...rest }) => {
    const onChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e);
      onChangeRange && onChangeRange(+e.currentTarget.value);
    };
    return (
      <div className={'flex flex-col items-stretch w-full'}>
        <input
          type={'range'}
          onChange={onChangeCallback}
          value={value}
          max={max}
          min={min}
          {...rest}
          list='tickmarks'
        />
        <datalist
          id='tickmarks'
          style={{ display: 'inherit' }}
          className={'justify-between'}
        >
          <option label={String(min)} className={'font-semibold'} />
          <option
            label={String(value)}
            className={'font-semibold bg-gray-200 px-3 rounded-lg shadow'}
          />
          <option label={String(max)} className={'font-semibold'} />
        </datalist>
      </div>
    );
  },
);
