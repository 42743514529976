import { FieldErrors, FieldValues } from 'react-hook-form';
import { ErrorMessage } from '../../index';
import { entries } from '../../../Shared/Utils/typedEntries';

type IServerErrorsProps<T extends FieldValues> = {
  errors: FieldErrors<T>;
};

export const ServerErrors = <T extends Record<string, any>>({
  errors,
}: IServerErrorsProps<T>) => {
  if (Object.keys(errors).length === 0) {
    return null;
  }

  const mappedErrors = entries(errors).reduce((acc, [, value]) => {
    if (value && value.type === 'server' && value.message) {
      return [...acc, value.message as string];
    }
    return acc;
  }, [] as string[]);

  if (!mappedErrors.length) {
    return null;
  }

  return <ErrorMessage errors={mappedErrors} />;
};
