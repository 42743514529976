import { authThunk } from '../../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { useTypedDispatch } from '../../hooks/useTypedDispatch';

const Logout = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(authThunk.logout());
    navigate('/');
  };

  return (
    <button
      onClick={handleLogout}
      className='block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100'
    >
      Logout
    </button>
  );
};

export default Logout;
