import { useState, useEffect } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';

import { API_ADDRESS } from '../../config/const';

import './EmailConfirmation.css';
import loader from '../../assets/svg/loader.svg';

export const EmailConfirmation = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [state, setState] = useState('');
  const [error, setError] = useState('');
  const [showEnterButton, setShowEnterButton] = useState(false);

  useEffect(() => {
    const token = searchParams.get('token');
    const activationURL = `${API_ADDRESS}/api/auth/activate/${token}/`;

    fetch(activationURL)
      .then(async (result) => {
        const response = await result.json();

        if (response.error_code) {
          setState('error');
          setError(response.description_for_users);
        } else {
          setState('confirmed');
          setTimeout(() => {
            navigate('/login');
          }, 3000);
          setTimeout(() => {
            setShowEnterButton(true);
          }, 6000);
        }
      })
      .catch((error) => {
        console.log(error);
        setState('error');
        setError('Неизвестная ошибка. Пожалуйста, обратитесь в поддержку.');
      });
  }, [searchParams]);

  return (
    <div className='content'>
      {state === 'confirmed' && (
        <div className='status-container'>
          <h1 className='status-text text-2xl font-semibold text-gray-900'>
            Email подтвержден
          </h1>
          <p>Сейчас вы будете перенаправлены на страницу входа.</p>
        </div>
      )}

      {state !== 'error' && (
        <>
          {!showEnterButton && (
            <img className='loader' src={loader} alt='loading' />
          )}

          {showEnterButton && (
            <Link
              to={'/login'}
              className='button flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10'
            >
              Войти
            </Link>
          )}
        </>
      )}

      {state === 'error' && (
        <>
          <div className='status-container'>
            <h1 className='status-text text-2xl font-semibold text-gray-900'>
              Возникла ошибка...
            </h1>
            <p>{error}</p>
          </div>

          <a
            className='button flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10'
            href='https://t.me/dm10101/'
            target='_blank'
            rel='noreferrer'
          >
            Написать в поддержку
          </a>
        </>
      )}
    </div>
  );
};
