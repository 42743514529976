import { InputHTMLAttributes } from 'react';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';
import { Input } from './Input';
import cn from 'classnames';

interface IControlledInputProps<T extends FieldValues>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  control: Control<T>;
  name: FieldPath<T>;
  rules?: Omit<
    RegisterOptions<T, FieldPath<T>>,
    'valueAsNumber' | 'valueAsDate' | 'disabled'
  >;
  showError?: boolean;
  showRequiredStar?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  valueAsNumber?: boolean;
}

export const ControlledInput = <T extends Record<string, any>>(
  props: IControlledInputProps<T>,
) => {
  const {
    showError = true,
    control,
    rules,
    name,
    onChange: outerOnChange,
    type = 'text',
    showRequiredStar = false,
    valueAsNumber = false,
    ...rest
  } = props;

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <div className={'relative flex flex-col mb-1'}>
          <div
            className={cn(
              'border rounded-md',
              error && error.type !== 'server' && showError && 'border-red-500',
            )}
          >
            {rules?.required && showRequiredStar && (
              <div
                className={
                  'absolute -top-2 -right-3 text-red-500 text-xl font-bold'
                }
              >
                *
              </div>
            )}
            <Input
              type={type}
              value={valueAsNumber ? Number(value) : (value || '').toString()}
              onChange={(e) => {
                const resultValue = outerOnChange
                  ? outerOnChange(e)
                  : e.target.value;
                onChange(resultValue);
              }}
              onBlur={onBlur}
              {...rest}
            />
          </div>
          {error && error.type !== 'server' && showError ? (
            <div className={'absolute top-11 left-2 text-sm text-red-500'}>
              {error.message}
            </div>
          ) : null}
        </div>
      )}
    />
  );
};
