import { validEmail, validPassword } from '../regExp/regExp';

export const validEmailRules = {
  required: 'Поле не должно быть пустым',
  pattern: {
    value: validEmail,
    message: 'Пожалуйста введите правильный адрес',
  },
};
export const validPasswordRules = {
  pattern: {
    value: validPassword,
    message: 'Только латинские буквы и цифры',
  },
  minLength: {
    value: 8,
    message: 'Не менее 8 символов',
  },
  maxLength: { value: 32, message: 'Не более 32 символов' },
};
