import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuth } from '../redux/slices/authSlice';
import { useGetMailingItemsArrayQuery } from '../services/RTKService/mailing/endpoints/mailingApi';
import { useGetHhUserDataQuery } from '../services/RTKService/hhUser/endpoints/hhUserApi';

export const useInitializeAuthedApp = () => {
  const isAuthed = useSelector(selectIsAuth);

  useEffect(() => {
    if (!isAuthed) {
      return;
    }
  }, [isAuthed]);

  useGetHhUserDataQuery(
    { customOptions: { showErrorToast: false } },
    {
      selectFromResult: ({ data, isLoading }) => ({
        data,
        isLoading,
      }),
      skip: !isAuthed,
    },
  );

  useGetMailingItemsArrayQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        data,
      }),
      skip: !isAuthed,
    },
  );
};
