import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosRequestBaseQueryFn } from './AxiosBaseQuery/axiosBaseQuery';
import { mailingInvalidationTags } from './mailing/ivalidationTags/invalidationTags';
import { invalidationTagsHhUser } from './hhUser/invalidationTags/invalidationTags';
import { invalidationTagsAuth } from './auth/invalidationTags/invalidationTags';

// убирает дубляж тегов
const overTagsTypes = new Set([
  ...mailingInvalidationTags,
  ...invalidationTagsHhUser,
  ...invalidationTagsAuth,
]);

export const pokemonApi = createApi({
  reducerPath: 'pokemonApi',
  baseQuery: axiosRequestBaseQueryFn(),
  //тайпскриптовые заморочки...
  tagTypes: [...overTagsTypes],
  endpoints: () => ({}),
});
