import { useForm, UseFormSetValue } from 'react-hook-form';
import { useGetMailingItemByIdQuery } from '../../../services/RTKService/mailing/endpoints/mailingApi';
import { useEffect } from 'react';
import { entries } from '../../../Shared/Utils/typedEntries';
import { TMailingTaskResponse } from '../../../services/RTKService/types/mailing-types/mailing.types';
import { useUpdateMailingTask } from './useUpdateMailingTask';
import { useCreateMailingTask } from './useCreateMailingTask';

const setTaskForms = (
  mailingTask: TMailingTaskResponse | undefined,
  setValue: UseFormSetValue<TMailingTaskResponse>,
) => {
  if (mailingTask) {
    entries(mailingTask).forEach((value) => {
      if (!value) return;
      setValue(value[0], value[1]);
    });
  }
};

export const useMailingSettings = (id?: number) => {
  const {
    formState: { dirtyFields },
    control,
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = useForm<TMailingTaskResponse>();

  const isChanged = Object.keys(dirtyFields).length !== 0;

  const {
    data: mailingTask,
    isLoading,
    refetch,
  } = useGetMailingItemByIdQuery(
    { id: id as number },
    {
      skip: !id,
    },
  );

  useEffect(() => {
    setTaskForms(mailingTask, setValue);
  }, [mailingTask]);

  // ---> Подглядываем за desired_applies_number_per_day полем и
  // если оно превышает desired_number_of_applies поле,
  // то меняем поле desired_number_of_applies так,
  // чтобы desired_applies_number_per_day не превышал desired_number_of_applies
  // и если desired_applies_number_per_day равен 0,
  // то и desired_number_of_applies сбрасывается на 0.
  // Следим за *desired_number_of_applies*, триггерим валидацию на каждое изменение поля.
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'desired_applies_number_per_day') {
        if (
          value.desired_number_of_applies === undefined ||
          value.desired_applies_number_per_day === undefined
        )
          return;

        if (
          +value.desired_number_of_applies <
          +value.desired_applies_number_per_day
        ) {
          setValue('desired_number_of_applies', value[name]);
          trigger('desired_number_of_applies');
        }
      } else if (name === 'desired_number_of_applies') {
        if (
          value.desired_number_of_applies === undefined ||
          value.desired_applies_number_per_day === undefined
        )
          return;

        trigger('desired_number_of_applies');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  // <---

  const reset = () => {
    setTaskForms(mailingTask, setValue);
  };

  const { updateTask, isLoading: isUpdateLoading } = useUpdateMailingTask();
  const { createTask, isLoading: isCreateLoading } = useCreateMailingTask();

  const onSubmit = handleSubmit(async (data) => {
    if (id) {
      await updateTask(data);
    } else {
      await createTask(data);
    }
    refetch();
  });

  return {
    control,
    onSubmit,
    isLoading,
    isUpdateLoading: isUpdateLoading || isCreateLoading,
    isChanged,
    reset,
  };
};
