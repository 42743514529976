import { ReactNode } from 'react';

import cn from 'classnames';

import './BlockingWrapper.css';

interface IFBlockingWrapper {
  children: ReactNode;
  isBlocked: boolean;
  blockContent: ReactNode;
}

export default function BlockingWrapper({
  children,
  isBlocked,
  blockContent,
}: IFBlockingWrapper) {
  return (
    <div
      className={cn('blockingWrapper', isBlocked && 'blockingWrapperBlocked')}
    >
      {children}

      {isBlocked && <div className='blockingWrapperBlock'>{blockContent}</div>}
    </div>
  );
}
