import React, { memo } from 'react';
import { ButtonWithLoader } from '../../../../Components';

import { useSelector } from 'react-redux';
import { _selectHhUser } from '../../../../services/RTKService/hhUser/HHUserSelectors/HhUserSelectors';
import { useGetStartStopMailingTask } from '../../mailingHooks/useGetStartStopMailingTask';

interface IControlButtonsGroupProps {
  id?: number;
}
export const ControlButtonsGroup: React.FC<IControlButtonsGroupProps> = memo(
  ({ id }) => {
    const hhUserData = useSelector(_selectHhUser);
    const { taskData, onStartTask, onStopTask, isLoading } =
      useGetStartStopMailingTask(id);

    const onClickStop = async () => {
      await onStopTask();
    };

    const onClickStart = async () => {
      await onStartTask();
    };

    if (hhUserData && taskData) {
      return (
        <div>
          {taskData.status === 'RUNNING' || taskData.status === 'PENDING' ? (
            <ButtonWithLoader
              className={'bg-red-500 hover:bg-red-400 focus:ring-red-400'}
              isLoading={isLoading}
              onClick={onClickStop}
            >
              Остановить рассылку
            </ButtonWithLoader>
          ) : (
            <ButtonWithLoader isLoading={isLoading} onClick={onClickStart}>
              Начать рассылку
            </ButtonWithLoader>
          )}
        </div>
      );
    } else return null;
  },
);
