import { MenuProps } from '../../inerfaces';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuth } from '../../redux/slices/authSlice';
import Logout from './Logout';
import AuthLinks from './AuthLinks';
import './Menu.css';
import logo from '../../assets/svg/hh mailer.svg';

export const MobileMenu = ({ active, setActive }: MenuProps) => {
  const isAuth = useSelector(selectIsAuth);

  return (
    <div className={active ? 'menu active' : 'menu'}>
      <div className='absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'>
        <div className='rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden'>
          <div className='px-5 pt-4 flex items-center justify-between'>
            <div>
              <img className='h-8 w-auto' src={logo} alt='' />
            </div>
            <div className='-mr-2'>
              <button
                type='button'
                onClick={() => setActive(false)}
                className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
              >
                <span className='sr-only'>Close menu</span>
                {/*Heroicon name: outline/x*/}
                <svg
                  className='h-6 w-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  aria-hidden='true'
                >
                  <path d='M6 18L18 6M6 6l12 12' />
                </svg>
              </button>
            </div>
          </div>
          <div className='px-2 pt-2 pb-3'>
            <Link
              to='#'
              className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50'
            >
              Product
            </Link>

            <Link
              to='#'
              className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50'
            >
              Features
            </Link>

            <Link
              to='#'
              className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50'
            >
              Marketplace
            </Link>

            <Link
              to='#'
              className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50'
            >
              Company
            </Link>
          </div>
          {isAuth ? <Logout /> : <AuthLinks />}
        </div>
      </div>
    </div>
  );
};
