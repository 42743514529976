import { InputHTMLAttributes } from 'react';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
} from 'react-hook-form';
import { Slider } from './Slider';

interface IHookFormControlledSliderProps<T extends FieldValues>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  control: Control<T>;
  name: FieldPath<T>;
  rules?: Omit<
    RegisterOptions<T, FieldPath<T>>,
    'valueAsNumber' | 'valueAsDate' | 'disabled'
  >;
  defaultValue?: PathValue<T, Path<T>>;
}

export const HookFormControlledSlider = <T extends Record<string, any>>(
  props: IHookFormControlledSliderProps<T>,
) => {
  const { control, defaultValue, rules, name, ...rest } = props;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      name={name}
      render={({ field: { value, onChange, onBlur } }) => (
        <Slider
          value={value || 0}
          onChangeRange={onChange}
          onBlur={onBlur}
          {...rest}
        />
      )}
    />
  );
};
