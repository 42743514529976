import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  selectError,
  selectIsShowErrorToast,
} from '../../../redux/slices/appSlice/appSlice';
import { useTypedDispatch } from '../../../hooks/useTypedDispatch';
import { appActions } from '../../../redux/slices/appSlice/appActions';
import { Toast } from '../Toast';

export const ErrorToast: React.FC = memo(() => {
  const errorMessage = useSelector(selectError);
  const isShowErrorToast = useSelector(selectIsShowErrorToast);
  const dispatch = useTypedDispatch();

  const onClose = useCallback(() => {
    dispatch(appActions.setError({ error: null }));
  }, []);

  if (!isShowErrorToast) {
    return null;
  }
  return (
    <Toast message={errorMessage} onClose={onClose} messageMode={'error'} />
  );
});
