import { useState } from 'react';
import { Logout, MobileMenu } from '../index';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuth } from '../../redux/slices/authSlice';
import logo from '../../assets/svg/hh mailer.svg';

export const Header = () => {
  const [burgerActive, setBurgerActive] = useState(false);
  const isAuth = useSelector(selectIsAuth);
  return (
    <header>
      <nav
        className='relative flex items-center justify-between sm:h-10 md:justify-center'
        aria-label='Global'
      >
        <div className='flex items-center flex-1 md:absolute md:inset-y-0 md:left-0'>
          <div className='flex items-center justify-between w-full md:w-auto'>
            <Link to={'/'}>
              <span className='sr-only'>Workflow</span>
              <img className='h-8 w-auto sm:h-10' src={logo} alt='' />
            </Link>
            <div className='-mr-2 flex items-center md:hidden'>
              <button
                type='button'
                className='bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
                aria-expanded='false'
                onClick={() => setBurgerActive(!burgerActive)}
              >
                <span className='sr-only'>Open main menu</span>
                <svg
                  className='h-6 w-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  aria-hidden='true'
                >
                  <path d='M4 6h16M4 12h16M4 18h16' />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {isAuth && <Logout />}
      </nav>
      <MobileMenu active={burgerActive} setActive={setBurgerActive} />
    </header>
  );
};
