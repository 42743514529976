import {
  useStartMailingTaskMutation,
  useStopMailingTaskMutation,
  useUpdateMailingItemMutation,
} from '../../../services/RTKService/mailing/endpoints/mailingApi';
import { TMailingTaskResponse } from '../../../services/RTKService/types/mailing-types/mailing.types';

export const useUpdateMailingTask = () => {
  const [start, { isLoading: startLoading }] = useStartMailingTaskMutation();
  const [stop, { isLoading: stopLoading }] = useStopMailingTaskMutation();
  const [update, { isLoading: isUpdateLoading }] =
    useUpdateMailingItemMutation();

  const updateTask = async (mailingTask: TMailingTaskResponse) => {
    if (
      mailingTask?.status === 'RUNNING' ||
      mailingTask?.status === 'PENDING'
    ) {
      await stop({
        id: mailingTask.id,
        customOptions: { showErrorToast: false },
      });
    } else {
      const res = await start({
        id: mailingTask.id,
        customOptions: { showErrorToast: false },
      });
      // с первого раза бек не  втыкает скорость, по этому пока так
      if ('error' in res) {
        await start({
          id: mailingTask.id,
          customOptions: { showErrorToast: false },
        });
      }
    }

    await update({ id: mailingTask.id, patchedMailing: mailingTask });
  };

  return {
    updateTask,
    isLoading: startLoading || stopLoading || isUpdateLoading,
  };
};
