// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  margin: 0;
}

.main__content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Main/Main.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".main {\n  margin: 0;\n}\n\n.main__content-wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.logo {\n  width: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
