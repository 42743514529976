import React, { memo } from 'react';
import { HookFormResumeSelector } from './selectors/HookFormResumeSelector';
import { HookFormRegionSelector } from './selectors/HookFormRegionSelector';
import { HookFormEmploymentTypeSelector } from './selectors/HookFormEmploymentTypeSelector';
import { HookFormOrderSelector } from './selectors/HookFormOrderSelector';
import { HookFormSearchPeriodSelector } from './selectors/HookFormSearchPeriodSelector';
import { DesiredVacancySearchKeyInput } from './inputs/DesiredVacancySearchKeyInput';
import { CoverLetterTextarea } from './inputs/CoverLetterTextarea';
import { DesiredNumberOfAppliesPerDayInput } from './inputs/DesiredNumberOfAppliesPerDayInput';
import { DesiredNumberOfAppliesTotalInput } from './inputs/DesiredNumberOfAppliesTotalInput';
import { useMailingSettings } from '../../mailingHooks/useMailingSettings';
import { ButtonWithLoader } from '../../../../Components';
import cn from 'classnames';

interface IHookFormMailingSettingsProps {
  id?: number;
}

export const HookFormMailingSettings: React.FC<IHookFormMailingSettingsProps> =
  memo(({ id }) => {
    const { control, onSubmit, isUpdateLoading, isChanged, reset } =
      useMailingSettings(id);

    return (
      <>
        <form
          className='space-y-8 divide-y divide-gray-200'
          onSubmit={onSubmit}
        >
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div className='pt-8 space-y-6 sm:pt-10 sm:space-y-5'>
              <div>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>
                  Настройки автоматической рассылки откликов
                </h3>
              </div>
              <div className='space-y-6 sm:space-y-5'>
                <HookFormResumeSelector control={control} />
                <HookFormRegionSelector control={control} />
                <DesiredVacancySearchKeyInput control={control} />
                <CoverLetterTextarea control={control} />
                <HookFormOrderSelector control={control} />
                <HookFormEmploymentTypeSelector control={control} />
                <HookFormSearchPeriodSelector control={control} />
                <DesiredNumberOfAppliesPerDayInput control={control} />
                <DesiredNumberOfAppliesTotalInput control={control} />
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex space-x-1 justify-end'>
              <ButtonWithLoader
                type='button'
                className={cn('w-24 bg-gray-500 hover:bg-gray-600 ')}
                onClick={reset}
              >
                Отменить
              </ButtonWithLoader>
              <ButtonWithLoader
                type='submit'
                disabled={!isChanged}
                isLoading={isUpdateLoading}
                className={cn('w-24', !isChanged && 'button_blocked')}
              >
                Сохранить
              </ButtonWithLoader>
            </div>
          </div>
        </form>
      </>
    );
  });
