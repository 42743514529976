import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { authThunk, selectIsAuth } from '../../redux/slices/authSlice';
import './Main.css';
import logo from '../../assets/svg/hh mailer.svg';
import { useTypedDispatch } from '../../hooks/useTypedDispatch';

export const Main = () => {
  const token = useSelector(selectIsAuth);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(authThunk.logout());
    navigate('/');
  };

  return (
    <main className='main mt-16 mx-auto max-w-7xl px-4 sm:mt-24'>
      <div className='main__content-wrapper text-center'>
        <img src={logo} className='logo' alt='logo' />
        <p className='mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl'>
          HH-mailer позволяет вам откликаться на вакансии HeadHunter <br />в
          автоматическом режиме
        </p>
        <div className='mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8'>
          {token ? (
            <>
              <div className='rounded-md shadow'>
                <Link
                  to={'/dashboard/statistics'}
                  className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10'
                >
                  Перейти в дашборд
                </Link>
              </div>
              <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
                <button
                  onClick={handleLogout}
                  className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10'
                >
                  Выйти
                </button>
              </div>
            </>
          ) : (
            <>
              <div className='rounded-md shadow'>
                <Link
                  to={'/register'}
                  className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10'
                >
                  Регистрация
                </Link>
              </div>
              <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
                <Link
                  to={'/login'}
                  className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10'
                >
                  Войти
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  );
};
