import React, { memo } from 'react';
import { Control } from 'react-hook-form';
import { HookFormReactSelector } from '../../../../../Components/Selector/ReactSelector/HookFormReactSelector';
import { SelectorWrapper } from './SelectorWrapper';
import { ISelectOption } from '../../../../../Components/Selector/ReactSelector/ReactSelector';
import { TMailingTaskResponse } from '../../../../../services/RTKService/types/mailing-types/mailing.types';

const orderOptions: ISelectOption[] = [
  { value: 'relevance', label: 'По соответствию' },
  { value: 'publication_time', label: 'По дате изменени' },
  { value: 'salary_desc', label: 'По убыванию зарплат' },
  { value: 'salary_asc', label: 'По возрастанию зарплаты' },
];
interface IHookFormOrderSelectorProps {
  control: Control<TMailingTaskResponse>;
}

export const HookFormOrderSelector: React.FC<IHookFormOrderSelectorProps> =
  memo(({ control }) => {
    return (
      <SelectorWrapper label={'Сортировать вакансии по'} options={orderOptions}>
        <HookFormReactSelector<TMailingTaskResponse>
          placeholder={'Выберете тип'}
          defaultValue={orderOptions[0].value}
          control={control}
          name={'order'}
          isLoading={false}
          isMulti={false}
          options={orderOptions}
          className={'w-full'}
        />
      </SelectorWrapper>
    );
  });
