import React, { memo } from 'react';
import { Input } from '../../../../Components';

type IInput = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >

export const StyledInput: React.FC<IInput> = memo((props) => {
  return (
    <div className='mt-6 grid grid-cols-12 gap-6'>
      <div className='col-span-12 sm:col-span-7 lg:col-span-5 xl:col-span-4'>
        <Input {...props} />
      </div>
    </div>
  );
});
