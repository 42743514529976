import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  selectIsShowSuccessToast,
  selectSuccessMessage,
} from '../../../redux/slices/appSlice/appSlice';
import { useTypedDispatch } from '../../../hooks/useTypedDispatch';
import { appActions } from '../../../redux/slices/appSlice/appActions';
import { Toast } from '../Toast';

export const SuccessToast: React.FC = memo(() => {
  const successMessage = useSelector(selectSuccessMessage);
  const isSuccessToast = useSelector(selectIsShowSuccessToast);
  const dispatch = useTypedDispatch();

  const onClose = useCallback(() => {
    dispatch(appActions.setShowSuccessToast(false));
  }, []);

  if (!isSuccessToast) {
    return null;
  }
  return (
    <Toast message={successMessage} onClose={onClose} messageMode={'success'} />
  );
});
