import './App.css';
import { Outlet, Route, Routes } from 'react-router-dom';
import {
  ConfirmEmail,
  EmailConfirmation,
  Main,
  Settings,
  SettingsHhAuth,
  SignIn,
  SignUp,
  Mailing,
  Dashboard,
} from './Pages';

import { ErrorToast, Hidden, SuccessToast } from './Components';

import { useInitializeApp } from './hooks/useInitializeApp';
import { useInitializeAuthedApp } from './hooks/useInitializeAuthedApp';

function OutsideRoutes() {
  return (
    <div className='outer-routes relative bg-gray-50 overflow-hidden landing-page__main'>
      <Hidden />
      <div className='outer-routes__wrapper relative pt-6 pb-16 sm:pb-24'>
        {/* <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <Header />
          </div> */}
        <Outlet />
      </div>
    </div>
  );
}

function App() {
  useInitializeApp();
  useInitializeAuthedApp();
  // TODO: remove after redesign
  document.documentElement.setAttribute('data-theme', 'dark');
  return (
    <>
      <SuccessToast />
      <ErrorToast />
      <Routes>
        <Route path='/' element={<OutsideRoutes />}>
          <Route path='/' element={<Main />} />
          <Route path='/login' element={<SignIn />} />
          <Route path='/register' element={<SignUp />} />
          <Route path='/email-confirmation' element={<EmailConfirmation />} />
          <Route path='/confirm-email' element={<ConfirmEmail />} />
        </Route>
        <Route path='dashboard' element={<Dashboard />}>
          {['statistics', '/dashboard'].map((path) => (
            <Route key={path} path={path} element={<Mailing />}>
              <></>
            </Route>
          ))}
          {/* <Route path="tasks" element={<Tasks />} /> */}
          <Route path='settings' element={<Settings />}>
            <Route path='hhauth' element={<SettingsHhAuth />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
