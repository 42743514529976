import React, { memo } from 'react';
import { HookFormReactSelector } from '../../../../../Components/Selector/ReactSelector/HookFormReactSelector';
import { SelectorWrapper } from './SelectorWrapper';
import { Control } from 'react-hook-form';
import { ISelectOption } from '../../../../../Components/Selector/ReactSelector/ReactSelector';
import { TMailingTaskResponse } from '../../../../../services/RTKService/types/mailing-types/mailing.types';

const searchPeriodOptions: ISelectOption[] = [
  { value: '0', label: 'За всё время' },
  { value: '30', label: 'За месяц' },
  { value: '7', label: 'За неделю' },
  { value: '3', label: 'За последние три дня' },
  { value: '1', label: 'За сутки' },
];
interface IHookFormSearchPeriodSelectorProps {
  control: Control<TMailingTaskResponse>;
}

export const HookFormSearchPeriodSelector: React.FC<IHookFormSearchPeriodSelectorProps> =
  memo(({ control }) => {
    return (
      <SelectorWrapper
        label={'Откликаться на вакансии опубликованные за'}
        options={searchPeriodOptions}
      >
        <HookFormReactSelector<TMailingTaskResponse>
          placeholder={'Выберете период'}
          defaultValue={searchPeriodOptions[0].value}
          control={control}
          name={'search_period'}
          isLoading={false}
          isMulti={false}
          options={searchPeriodOptions}
          className={'w-full'}
        />
      </SelectorWrapper>
    );
  });
