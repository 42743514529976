import './SignIn.css';
import { SignLayout } from '../../Components';
import { useSignIn } from './useSignIn';

export const SignIn = () => {
  const { control, onSubmit, onLink, isLoading, errors } = useSignIn();

  return (
    <SignLayout
      isLoading={isLoading}
      onSubmit={onSubmit}
      control={control}
      heading={'Авторизация'}
      onLink={onLink}
      errors={errors}
      linkName={'Регистрация'}
    />
  );
};
