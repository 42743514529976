import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import { appSlice } from './slices/appSlice/appSlice';
import { pokemonApi } from '../services/RTKService/RTKService';
import { setupListeners } from '@reduxjs/toolkit/query';
import { getStorageItem } from '../services/localStorageService/localStorageService';

export type RootReducerType = typeof rootReducer;
export type AppDispatchType = typeof store.dispatch;
export type AppRootStateType = ReturnType<RootReducerType>;

export const rootReducer = combineReducers({
  user: authSlice,
  app: appSlice.reducer,
  [pokemonApi.reducerPath]: pokemonApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(pokemonApi.middleware),
  preloadedState: {
    user: { isAuth: getStorageItem('isAuth') },
  },
});

setupListeners(store.dispatch);
