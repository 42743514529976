import { createAsyncThunk } from '@reduxjs/toolkit';

const initializeApp = createAsyncThunk(
  'app/initializeApplication',
  async (_) => {
    // console.log('здесь будут запросы');
  },
);

export const AppAsyncActions = {
  initializeApp,
};
