// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile {
    display: none;
}

.top {
    margin-bottom: 25px;
}

.buttonsDesk {
    display: flex;
    flex-direction: row;
}

.newTask__button {
    margin-right: 10px;
}

.mobSelectCont {
    margin-top: 8px;
    display: none;
}

.tasksCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
}

.column {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 500px){
    .desktop, .desktopSelect {
        display: none;
    }
    .mobile, .mobSelectCont {
        display: block;
    }
    .top {
        margin-bottom: 3px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Tasks/Tasks.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,cAAc;IAClB;IACA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".mobile {\n    display: none;\n}\n\n.top {\n    margin-bottom: 25px;\n}\n\n.buttonsDesk {\n    display: flex;\n    flex-direction: row;\n}\n\n.newTask__button {\n    margin-right: 10px;\n}\n\n.mobSelectCont {\n    margin-top: 8px;\n    display: none;\n}\n\n.tasksCont {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    padding: 12px 0;\n}\n\n.column {\n    display: flex;\n    flex-direction: column;\n}\n\n@media screen and (max-width: 500px){\n    .desktop, .desktopSelect {\n        display: none;\n    }\n    .mobile, .mobSelectCont {\n        display: block;\n    }\n    .top {\n        margin-bottom: 3px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
