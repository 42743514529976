import { Link } from 'react-router-dom';

export const AuthButtons = () => {
  return (
    <div className='hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0'>
      <span className='inline-flex rounded-md shadow'>
        <Link
          to={'/auth'}
          className='inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50'
        >
          Авторизация
        </Link>
      </span>
      <div style={{ marginLeft: 5 }}>
        <span className='inline-flex rounded-md shadow'>
          <Link
            to={'/reg'}
            className='inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50'
          >
            Регистрация
          </Link>
        </span>
      </div>
    </div>
  );
};
