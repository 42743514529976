import { useCreateMailingItemMutation } from '../../../services/RTKService/mailing/endpoints/mailingApi';
import { useSelector } from 'react-redux';
import { selectHhUserId } from '../../../services/RTKService/hhUser/HHUserSelectors/HhUserSelectors';
import { TCreateMailingTaskPayload } from '../../../services/RTKService/types/mailing-types/mailing.types';

export const useCreateMailingTask = () => {
  const hhUserId = useSelector(selectHhUserId);
  const [create, { isLoading }] = useCreateMailingItemMutation();

  const createTask = async (data: TCreateMailingTaskPayload) => {
    if (hhUserId) {
      await create({ data: { ...data, hh_user: hhUserId } });
    }
  };
  return { createTask, isLoading };
};
