import { useSelector } from 'react-redux';
import { selectIsAppInitialized } from '../redux/slices/appSlice/appSlice';
import { useTypedDispatch } from './useTypedDispatch';
import { useEffect, useMemo } from 'react';
import { AppAsyncActions } from '../redux/slices/appSlice/appAsyncActions';

export const useInitializeApp = () => {
  const isAppInitialized = useSelector(selectIsAppInitialized);
  const dispatch = useTypedDispatch();
  useEffect(() => {
    if (isAppInitialized === 'idle') {
      dispatch(AppAsyncActions.initializeApp());
    }
  });
  return useMemo(() => ({ isAppInitialized }), []);
};
