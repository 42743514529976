import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { authThunk, selectIsAuth } from '../../redux/slices/authSlice';
import { useTypedDispatch } from '../../hooks/useTypedDispatch';
import { useNavigate, useLocation } from 'react-router-dom';

function checkIsUserAuthorized({
  isAuth,
  pathname,
  openRoutes,
}: {
  isAuth: boolean;
  pathname: string;
  openRoutes: string[];
}): boolean {
  return (
    isAuth ||
    !!openRoutes.find((route: string) => {
      let isAuthorized;

      if (route === '/') {
        isAuthorized = route === pathname;
      } else {
        isAuthorized = route === pathname || `${route}/` === pathname;
      }

      return isAuthorized;
    })
  );
}

export const useAuthorize = ({ openRoutes }: { openRoutes: string[] }) => {
  const { pathname }: { pathname: string } = useLocation();
  const isAuth: boolean = useSelector(selectIsAuth);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [isUserAuthorized, setIsUserAuthorized]: [
    boolean,
    Dispatch<SetStateAction<boolean>>,
  ] = useState(checkIsUserAuthorized({ isAuth, pathname, openRoutes }));

  function logout() {
    dispatch(authThunk.logout());
    navigate('/login');
  }

  useEffect(() => {
    const isAuthorized = checkIsUserAuthorized({
      isAuth,
      pathname,
      openRoutes,
    });
    setIsUserAuthorized(isAuthorized);

    !isAuthorized && logout();
  }, [isAuth, pathname]);

  return isUserAuthorized;
};
