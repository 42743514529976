import React, { memo } from 'react';

import { HookFormReactSelector } from '../../../../../Components/Selector/ReactSelector/HookFormReactSelector';
import { SelectorWrapper } from './SelectorWrapper';
import { Control } from 'react-hook-form';
import { ISelectOption } from '../../../../../Components/Selector/ReactSelector/ReactSelector';
import { TMailingTaskResponse } from '../../../../../services/RTKService/types/mailing-types/mailing.types';

const employmentType: ISelectOption[] = [
  { value: 'full', label: 'Полная занятость' },
  { value: 'part', label: 'Частичная занятость' },
  { value: 'project', label: 'Проектная деятельность' },
  { value: 'volunteer', label: 'Волонтерство' },
  { value: 'probation', label: 'Стажировка' },
];

interface IHookFormEmploymentTypeSelectorProps {
  control: Control<TMailingTaskResponse>;
}

export const HookFormEmploymentTypeSelector: React.FC<IHookFormEmploymentTypeSelectorProps> =
  memo(({ control }) => {
    return (
      <SelectorWrapper label={'Тип работы'} options={employmentType}>
        <HookFormReactSelector<TMailingTaskResponse>
          placeholder={'Тип работы'}
          defaultValue={[employmentType[0].value, employmentType[1].value]}
          control={control}
          name={'employment_type'}
          isLoading={false}
          isMulti={true}
          options={employmentType}
          className={'w-full'}
        />
      </SelectorWrapper>
    );
  });
