import { pokemonApi } from '../../RTKService';
import { getAuthURL } from '../../../getURLHelpers/getURLHelpers';
import {
  AuthPayloadType,
  AuthResponseType,
  RegisterResponseType,
} from '../../types/authTypes/auth.types';
import { RequestOptionsType } from '../../../request';

export const authApi = pokemonApi.injectEndpoints({
  endpoints: (build) => ({
    getAuthData: build.query<
      AuthResponseType,
      { customOptions?: RequestOptionsType }
    >({
      query: ({ customOptions }) => ({
        url: getAuthURL('user'),
        method: 'get',
        customOptions,
      }),
      providesTags: ['login', 'patchUser'],
    }),
    register: build.mutation<
      RegisterResponseType,
      { data: AuthPayloadType; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        method: 'post',
        url: getAuthURL('register'),
        data,
        customOptions,
      }),
    }),
    login: build.mutation<
      AuthResponseType,
      { data: AuthPayloadType; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        method: 'post',
        url: getAuthURL('login'),
        data,
        customOptions,
      }),
      invalidatesTags: ['login'],
    }),
    update: build.mutation<
      AuthResponseType,
      { data: Partial<AuthPayloadType>; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        method: 'patch',
        url: getAuthURL('user'),
        data,
        customOptions,
      }),
      invalidatesTags: ['patchUser'],
    }),
  }),
});
export const {
  useGetAuthDataQuery,
  useUpdateMutation,
  useLoginMutation,
  useRegisterMutation,
} = authApi;
