import { format } from 'date-fns';
import './Stats.css';
import {
  useGetMailingItemByIdQuery,
  useGetSearchLinkQuery,
} from '../../services/RTKService/mailing/endpoints/mailingApi';

const MAILING_STATUS_CONST = {
  RUNNING: 'Запущенно',
  STOPPED: 'Остановленно',
  PENDING: 'В обработке',
  ERROR: 'Ошибка',
  PLAN_FULFILLED: 'Полностью выполнено',
  DAILY_PLAN_FULFILLED: 'На сегодня выполнено',
  OUT_OF_VACANCIES: 'Вакансии отсутствуют',
  HH_LIMIT_REACHED: 'Достигнут лимит',
  CREATED: 'Созданно',
};

interface props {
  taskId?: number;
  className?: string;
}

export default function Stats({ taskId }: props) {
  const { statistics, mailingStatus } = useGetMailingItemByIdQuery(
    { id: taskId as number },
    {
      skip: !taskId,
      selectFromResult: ({ data }) => ({
        statistics: data && {
          total: data.applies_count,
          perDay: data.applies_count_today,
          lastRunTime: data.last_period_first_run_time,
          nextRunTime: data.next_run_time,
        },
        mailingStatus: data?.status && MAILING_STATUS_CONST[data?.status],
        data,
      }),
    },
  );

  const { link } = useGetSearchLinkQuery(
    { id: taskId as number },
    {
      skip: !taskId,
      selectFromResult: ({ data }) => ({
        link: data,
      }),
    },
  );

  if (!statistics && !link) return null;

  return (
    <div className='flex flex-col space-y-1 px-4 py-5 bg-gray-200 shadow-xl rounded-lg overflow-hidden'>
      {mailingStatus ? (
        <div className='font-medium text-gray-500 truncate'>
          Статус рассылки:{' '}
          <span className={'text-indigo-600'}>{mailingStatus}</span>
        </div>
      ) : null}
      {statistics ? (
        <>
          <div className=' font-medium text-gray-500 truncate'>
            Количество откликов сегодня: {statistics.perDay} из 200
          </div>
          <div className=' font-medium text-gray-500 truncate'>
            Количество откликов всего: {statistics.total}{' '}
          </div>
          {statistics.lastRunTime ? (
            <div className=' font-medium text-gray-500 truncate'>
              Последний запуск:{' '}
              {format(statistics.lastRunTime, 'dd-MM-yyyy HH:mm:ss')}
            </div>
          ) : null}
          {statistics.nextRunTime ? (
            <div className=' font-medium text-gray-500 truncate'>
              Следующий запуск:{' '}
              {format(statistics.nextRunTime, 'dd-MM-yyyy HH:mm:ss')}
            </div>
          ) : null}
        </>
      ) : null}
      {link ? (
        <div className='font-medium text-gray-500 truncate'>
          Отклики будут разосланы на следующие вакансии:{' '}
          <a
            className='font-extralight text-indigo-600 truncate hover:underline'
            target='_blank'
            href={link}
            rel='noreferrer'
          >
            Ссылка на страницу поиска вакансий
          </a>
        </div>
      ) : null}
    </div>
  );
}
