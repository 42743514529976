import React, { memo } from 'react';
import { HookFormControlledTextarea } from '../../../../../Components';
import { InputWrapper } from './InputWrapper';
import { Control } from 'react-hook-form';
import { TMailingTaskResponse } from '../../../../../services/RTKService/types/mailing-types/mailing.types';

interface ICoverLetterInputProps {
  control: Control<TMailingTaskResponse>;
}

export const CoverLetterTextarea: React.FC<ICoverLetterInputProps> = memo(
  ({ control }) => {
    return (
      <InputWrapper label={'Сопроводительное письмо'}>
        <HookFormControlledTextarea<TMailingTaskResponse>
          name={'letter'}
          control={control}
          showError={false}
          placeholder={'Сопроводительное письмо'}
          rows={5}
        />
      </InputWrapper>
    );
  },
);
