import React, { memo } from 'react';
import cn from 'classnames';

type ITextareaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export const Textarea: React.FC<ITextareaProps> = memo(
  ({ className, ...rest }) => {
    return (
      <textarea
        className={cn(
          'block w-full border border-gray-300 rounded-md transition-colors shadow-sm py-2 px-3 hover:border-gray-400 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm',
          className,
        )}
        {...rest}
      />
    );
  },
);
