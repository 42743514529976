import { createEntityAdapter } from '@reduxjs/toolkit';
import type { EntityState } from '@reduxjs/toolkit';

import { pokemonApi } from '../../RTKService';
import { RequestOptionsType } from '../../../request';
import { WSInstance } from '../../../instance';

import {
  getMailingURL,
  getRegionCodesURL,
} from '../../../getURLHelpers/getURLHelpers';
import {
  TCreateMailingTaskPayload,
  TMailingTaskResponse,
  TRegionCodesItem,
  TUpdateMailingTaskPayload,
} from '../../types/mailing-types/mailing.types';

const mailingAdapter = createEntityAdapter<TMailingTaskResponse>();

export const mailingApi = pokemonApi.injectEndpoints({
  endpoints: (builder) => ({
    startMailingTask: builder.mutation<
      'Task was started',
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getMailingURL(`/${id}/start/`),
        method: 'post',
        customOptions,
      }),
      invalidatesTags: ['startTask'],
    }),
    stopMailingTask: builder.mutation<
      'Task was stopped',
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getMailingURL(`/${id}/stop/`),
        method: 'post',
        customOptions,
      }),
      invalidatesTags: ['stopTask'],
    }),

    getMailingItemById: builder.query<
      TMailingTaskResponse,
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getMailingURL(`/${id}/`),
        customOptions,
      }),
      async onCacheEntryAdded(
        { id },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        // create a websocket connection when the cache subscription starts
        let ws;
        try {
          ws = new WSInstance(getMailingURL(`/${id}/`));
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event: MessageEvent) => {
            updateCachedData(() => JSON.parse(event.data));
          };

          ws.addEventListener('message', listener);
        } catch (error) {
          console.error(error);
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved,
          // perform cleanup steps once the `cacheEntryRemoved` promise resolves
          ws && ws.close();
      },
    }),

    getMailingItemsArray: builder.query<TMailingTaskResponse[], object>({
      query: () => ({
        url: getMailingURL(),
        customOptions: { showErrorToast: false },
      }),
      providesTags: ['postTask', 'deleteHhUser'],
    }),

    createMailingItem: builder.mutation<
      TMailingTaskResponse,
      { data: TCreateMailingTaskPayload; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        url: getMailingURL(),
        method: 'post',
        data,
        customOptions,
      }),
      invalidatesTags: ['postTask'],
    }),

    updateMailingItem: builder.mutation<
      TMailingTaskResponse,
      {
        id: number;
        patchedMailing: TUpdateMailingTaskPayload;
      }
    >({
      query: ({ id, patchedMailing }) => ({
        url: getMailingURL(`/${id}/`),
        method: 'PATCH',
        data: patchedMailing,
      }),
      invalidatesTags: ['patchTask'],
    }),
    getCodeRegions: builder.query<
      TRegionCodesItem[],
      { customOptions?: RequestOptionsType }
    >({
      query: ({ customOptions }) => ({
        url: getRegionCodesURL(),
        method: 'get',
        customOptions,
      }),
    }),
    getSearchLink: builder.query<
      string,
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getMailingURL(`/${id}/search_link/`),
        method: 'get',
        customOptions,
      }),
      providesTags: ['patchTask'],
    }),
  }),
});

export const {
  useGetMailingItemByIdQuery,
  useGetMailingItemsArrayQuery,
  useUpdateMailingItemMutation,
  useStartMailingTaskMutation,
  useStopMailingTaskMutation,
  useCreateMailingItemMutation,
  useGetCodeRegionsQuery,
  useGetSearchLinkQuery,
} = mailingApi;
