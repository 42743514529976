import {
  useGetMailingItemByIdQuery,
  useStartMailingTaskMutation,
  useStopMailingTaskMutation,
} from '../../../services/RTKService/mailing/endpoints/mailingApi';

export const useGetStartStopMailingTask = (id?: number) => {
  const {
    data: taskData,
    isLoading,
    isSuccess,
    refetch,
  } = useGetMailingItemByIdQuery(
    { id: id as number },
    {
      skip: !id,
    },
  );

  const [onStart, { isLoading: isLoadingStart }] =
    useStartMailingTaskMutation();
  const [onStop, { isLoading: isLoadingStop }] = useStopMailingTaskMutation();

  const onStartTask = async () => {
    if (isSuccess) {
      await onStart({
        id: id as number,
        customOptions: { showErrorToast: false },
      });
      refetch();
    }
  };
  const onStopTask = async () => {
    if (isSuccess) {
      await onStop({
        id: id as number,
        customOptions: { showErrorToast: false },
      });
      refetch();
    }
  };
  return {
    taskData,
    onStartTask,
    onStopTask,
    isLoading: isLoading || isLoadingStart || isLoadingStop,
  };
};
