import React, { DetailedHTMLProps, memo, PropsWithChildren } from 'react';
import loaderWhite from '../../assets/svg/loaderWhite.svg';
import cn from 'classnames';
import './ButtonWithLoader.css';

interface IButtonWithLoaderProps
  extends DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isLoading?: boolean;
  loaderProps?: DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  loaderClassName?: string;
}

export const ButtonWithLoader: React.FC<
  PropsWithChildren<IButtonWithLoaderProps>
> = memo((props) => {
  const {
    children,
    loaderProps,
    loaderClassName,
    isLoading,
    className,
    ...rest
  } = props;
  return (
    <button
      className={cn(
        'buttonWithLoader w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        className && className,
      )}
      {...rest}
    >
      <>{children}</>
      {isLoading && (
        <img
          className={cn('w-5 h-5', loaderClassName && loaderClassName)}
          src={loaderProps?.src ? loaderProps.src : loaderWhite}
          alt='loading'
          {...loaderProps}
        />
      )}
    </button>
  );
});
