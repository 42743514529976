import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { removeStorageItemsArray } from '../../services/localStorageService/localStorageService';
import { AppRootStateType } from '../store';
import { AuthResponseType } from '../../services/RTKService/types/authTypes/auth.types';
import { setUserInLocalStorage } from '../../Shared/Utils/localStorage/setUserInLocalStorage';
import { pokemonApi } from '../../services/RTKService/RTKService';

export interface initState {
  isAuth: boolean;
}

export const authThunk = {
  login: createAsyncThunk<AuthResponseType | undefined, AuthResponseType>(
    'user/login/thunk',
    async (data, { rejectWithValue, dispatch }) => {
      try {
        await setUserInLocalStorage(data);
        dispatch(authSlice.actions.login());
        return data;
      } catch (e) {
        rejectWithValue(e);
      }
    },
  ),

  logout: createAsyncThunk('user/logout/thunk', async (_, { dispatch }) => {
    removeStorageItemsArray([
      'token',
      'isAuth',
      'email',
      'path',
      'region_codes',
      'user',
    ]);
    dispatch(authSlice.actions.logout());
    dispatch(pokemonApi.util.resetApiState());
  }),
};

const initialState: initState = {
  isAuth: false,
};

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state) => {
      state.isAuth = true;
    },
    logout: (state) => {
      state.isAuth = false;
    },
  },
});

export const selectIsAuth = (state: AppRootStateType) => state.user.isAuth;
export default authSlice.reducer;
