export const setStorageItem = (key: string, data: unknown) => {
  try {
    const serializedData = JSON.stringify(data);
    localStorage.setItem(key, serializedData);
  } catch {
    //ignore errors
  }
};

export const getStorageItem = (key: string) => {
  try {
    const serializedData = localStorage.getItem(key);
    if (serializedData === null) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (err) {
    return undefined;
  }
};

export const removeStorageItem = (key: string) => {
  localStorage.removeItem(key);
};
export const removeStorageItemsArray = (keys: string[]) => {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};
