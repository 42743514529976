import { isErrorWithDescription } from '../Utils/isApiError';

export const addServerErrors = (
  res: { error: unknown },
  setError: (...args: any[]) => void,
) => {
  setError('email', {
    type: 'server',
    message: isErrorWithDescription(res.error)
      ? res.error.data.description_for_users
      : 'Ошибка соединения',
  });
};
