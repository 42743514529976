import { authThunk } from '../../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { useTypedDispatch } from '../../hooks/useTypedDispatch';

export const Logout = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(authThunk.logout());
    navigate('/');
  };

  return (
    <div className='hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0'>
      <span className='inline-flex rounded-md shadow'>
        <button
          onClick={handleLogout}
          className='inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50'
        >
          Выход
        </button>
      </span>
    </div>
  );
};
