import { pokemonApi } from '../../RTKService';

import { getHhUserURL } from '../../../getURLHelpers/getURLHelpers';
import { RequestOptionsType } from '../../../request';
import {
  AuthByType,
  HhUserEntity,
  HhUserPayloadType,
} from '../../types/hhUser-types/hhUser.types';

export const hhUserApi = pokemonApi.injectEndpoints({
  endpoints: (build) => ({
    getHhUserData: build.query<
      HhUserEntity[],
      { customOptions?: RequestOptionsType; params?: { hh_data: boolean } }
    >({
      query: ({ customOptions, params = { hh_data: true } }) => ({
        url: getHhUserURL(),
        customOptions,
        params,
      }),
      providesTags: ['postHhUser', 'patchHhUser', 'deleteHhUser'],
    }),

    deleteHhUser: build.mutation<
      HhUserEntity,
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getHhUserURL(`/${id}/`),
        method: 'delete',
        customOptions,
      }),
      invalidatesTags: ['deleteHhUser'],
    }),

    patchUserDataByCookie: build.mutation<
      HhUserEntity,
      {
        data: { cookies: string };
        hhUserId: string;
        auth_by: AuthByType;
        customOptions?: RequestOptionsType;
      }
    >({
      query: ({ data, hhUserId, auth_by, customOptions }) => ({
        url: getHhUserURL(`/${hhUserId}/`),
        params: { auth_by },
        method: 'patch',
        data,
        customOptions,
      }),
      invalidatesTags: ['patchHhUser'],
    }),
    postHhUser: build.mutation<
      HhUserEntity,
      {
        data: Omit<HhUserPayloadType, 'email' | 'password'>;
        customOptions?: RequestOptionsType;
      }
    >({
      query: ({ data, customOptions }) => ({
        url: getHhUserURL(),
        method: 'post',
        data,
        customOptions,
      }),
      invalidatesTags: ['postHhUser'],
    }),
  }),
});

export const { useGetHhUserDataQuery } = hhUserApi;
