import React, { memo } from 'react';
import cn from 'classnames';

interface IHeadingProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  title?: string;
}

export const Heading: React.FC<IHeadingProps> = memo(
  ({ title = null, className, ...rest }) => {
    return (
      <h1
        className={cn('text-2xl font-semibold text-gray-900', className)}
        {...rest}
      >
        {title}
      </h1>
    );
  },
);
