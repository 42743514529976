import { memo, useMemo } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

import cn from 'classnames';

export const Settings = memo(() => {
  const location = useLocation();
  const isActiveHhauth = location.pathname === '/dashboard/settings/hhauth';

  const mappedLinks = useMemo(() => {
    const tabs = [
      {
        name: 'Аккаунты HH.ru',
        href: '/dashboard/settings/hhauth',
        current: isActiveHhauth,
      },
    ];

    return tabs.map((tab) => {
      const onSaveRoute = () => {
        localStorage.setItem('path', tab.href);
      };
      return (
        <NavLink
          onClick={onSaveRoute}
          key={tab.name}
          to={tab.href}
          className={cn(
            tab.current
              ? 'border-indigo-500 text-indigo-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
            'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
          )}
          aria-current={tab.current ? 'page' : undefined}
        >
          {tab.name}
        </NavLink>
      );
    });
  }, [location.pathname]);

  return (
    <div style={{ marginTop: 8 }}>
      <div className='border-b border-gray-200 flex justify-start'>
        <div className='sm:flex sm:items-baseline'>
          <div className='mt-4 sm:mt-0'>
            <nav className='-mb-px flex space-x-8'>{mappedLinks}</nav>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
});
