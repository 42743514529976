import { createAction } from '@reduxjs/toolkit';

const setError = createAction<{ error: string | null }>('appActions/setError');
const setSuccessMessage = createAction<{ message: string | null }>(
  'appActions/setSuccessMessage',
);
const setIsLoading = createAction<boolean>('appActions/setIsLoading');
const setShowErrorToast = createAction<boolean>('appActions/setShowErrorToast');
const setShowSuccessToast = createAction<boolean>(
  'appActions/setShowSuccessToast',
);

export const appActions = {
  setError,
  setIsLoading,
  setShowErrorToast,
  setShowSuccessToast,
  setSuccessMessage,
};
