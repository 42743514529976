import { useState } from 'react';
import { Disclosure, Menu, Switch, Transition } from '@headlessui/react';

import {
  BellIcon,
  CogIcon,
  CreditCardIcon,
  KeyIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';

const user = {
  name: 'Debbie Lewis',
  handle: 'deblewis',
  email: 'debbielewis@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80',
};
const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Jobs', href: '#', current: false },
  { name: 'Applicants', href: '#', current: false },
  { name: 'Company', href: '#', current: false },
];
const subNavigation = [
  { name: 'Profile', href: '#', icon: UserCircleIcon, current: true },
  { name: 'Account', href: '#', icon: CogIcon, current: false },
  { name: 'Password', href: '#', icon: KeyIcon, current: false },
  { name: 'Notifications', href: '#', icon: BellIcon, current: false },
  { name: 'Billing', href: '#', icon: CreditCardIcon, current: false },
];
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
];

export const Profile = () => {
  const [availableToHire, setAvailableToHire] = useState(true);
  const [privateAccount, setPrivateAccount] = useState(false);
  const [allowCommenting, setAllowCommenting] = useState(true);
  const [allowMentions, setAllowMentions] = useState(true);

  return (
    <>
      <Disclosure
        as='div'
        className='relative bg-sky-700 pb-32 overflow-hidden'
      >
        {() => (
          <>
            <nav
              className={
                'relative z-10 border-b border-teal-500 border-opacity-25 lg:bg-transparent lg:border-none'
              }
            >
              <div className='max-w-7xl mx-auto px-2 sm:px-4 lg:px-8'>
                <div className='relative h-16 flex items-center justify-between lg:border-b lg:border-sky-800'>
                  <div className='px-2 flex items-center lg:px-0'>
                    <div className='flex-shrink-0'></div>
                    <div className='hidden lg:block lg:ml-6 lg:space-x-4'>
                      <div className='flex'>
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={
                              'rounded-md py-2 px-3 text-sm font-medium text-white'
                            }
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/*<div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">*/}
                  {/*    <div className="max-w-lg w-full lg:max-w-xs">*/}
                  {/*        <label htmlFor="search" className="sr-only">*/}
                  {/*            Search*/}
                  {/*        </label>*/}
                  {/*        <div className="relative text-sky-100 focus-within:text-gray-400">*/}
                  {/*            <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">*/}
                  {/*                /!*<SearchIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />*!/*/}
                  {/*            </div>*/}
                  {/*            <input*/}
                  {/*                id="search"*/}
                  {/*                name="search"*/}
                  {/*                className="block w-full bg-sky-700 bg-opacity-50 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 placeholder-sky-100 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"*/}
                  {/*                placeholder="Search"*/}
                  {/*                type="search"*/}
                  {/*            />*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  <div className='flex lg:hidden'>
                    {/* Mobile menu button */}
                    <Disclosure.Button className='p-2 rounded-md inline-flex items-center justify-center text-sky-200 hover:text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                      <span className='sr-only'>Open main menu</span>
                      {/*{open ? (*/}
                      {/*    <XIcon className="block flex-shrink-0 h-6 w-6" aria-hidden="true" />*/}
                      {/*) : (*/}
                      {/*    <MenuIcon className="block flex-shrink-0 h-6 w-6" aria-hidden="true" />*/}
                      {/*)}*/}
                    </Disclosure.Button>
                  </div>
                  <div className='hidden lg:block lg:ml-4'>
                    <div className='flex items-center'>
                      <button className='flex-shrink-0 rounded-full p-1 text-sky-200 hover:bg-sky-800 hover:text-white focus:outline-none focus:bg-sky-900 focus:ring-2 focus:ring-offset-2 focus:ring-offset-sky-900 focus:ring-white'>
                        <span className='sr-only'>View notifications</span>
                        <BellIcon className='h-6 w-6' aria-hidden='true' />
                      </button>

                      {/* Profile dropdown */}
                      <Menu as='div' className='relative flex-shrink-0 ml-4'>
                        {({ open }) => (
                          <>
                            <div>
                              <Menu.Button className='rounded-full flex text-sm text-white focus:outline-none focus:bg-sky-900 focus:ring-2 focus:ring-offset-2 focus:ring-offset-sky-900 focus:ring-white'>
                                <span className='sr-only'>Open user menu</span>
                                <img
                                  className='rounded-full h-8 w-8'
                                  src={user.imageUrl}
                                  alt=''
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              show={open}
                              enter='transition ease-out duration-100'
                              enterFrom='transform opacity-0 scale-95'
                              enterTo='transform opacity-100 scale-100'
                              leave='transition ease-in duration-75'
                              leaveFrom='transform opacity-100 scale-100'
                              leaveTo='transform opacity-0 scale-95'
                            >
                              <Menu.Items
                                static
                                className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                              >
                                {userNavigation.map((item) => (
                                  <Menu.Item key={item.name}>
                                    {() => (
                                      <a
                                        href={item.href}
                                        className={
                                          'block py-2 px-4 text-sm text-gray-700'
                                        }
                                      >
                                        {item.name}
                                      </a>
                                    )}
                                  </Menu.Item>
                                ))}
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className='bg-sky-900 lg:hidden'>
                <div className='pt-2 pb-3 px-2 space-y-1'>
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={
                        'block rounded-md py-2 px-3 text-base font-medium text-white'
                      }
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className='pt-4 pb-3 border-t border-sky-800'>
                  <div className='flex items-center px-4'>
                    <div className='flex-shrink-0'>
                      <img
                        className='rounded-full h-10 w-10'
                        src={user.imageUrl}
                        alt=''
                      />
                    </div>
                    <div className='ml-3'>
                      <div className='text-base font-medium text-white'>
                        {user.name}
                      </div>
                      <div className='text-sm font-medium text-sky-200'>
                        {user.email}
                      </div>
                    </div>
                    <button className='ml-auto flex-shrink-0 rounded-full p-1 text-sky-200 hover:bg-sky-800 hover:text-white focus:outline-none focus:bg-sky-900 focus:ring-2 focus:ring-offset-2 focus:ring-offset-sky-900 focus:ring-white'>
                      <span className='sr-only'>View notifications</span>
                      <BellIcon className='h-6 w-6' aria-hidden='true' />
                    </button>
                  </div>
                  <div className='mt-3 px-2'>
                    {userNavigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className='block rounded-md py-2 px-3 text-base font-medium text-sky-200 hover:text-white hover:bg-sky-800'
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </nav>
            <div
              className={
                'absolute flex justify-center inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0'
              }
              aria-hidden='true'
            >
              <div className='flex-grow bg-sky-900 bg-opacity-75' />
              <svg
                className='flex-shrink-0'
                width={1750}
                height={308}
                viewBox='0 0 1750 308'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  opacity='.75'
                  d='M1465.84 308L16.816 0H1750v308h-284.16z'
                  fill='#075985'
                />
                <path
                  opacity='.75'
                  d='M1733.19 0L284.161 308H0V0h1733.19z'
                  fill='#0c4a6e'
                />
              </svg>
              <div className='flex-grow bg-sky-800 bg-opacity-75' />
            </div>
            <header className='relative py-10'>
              <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
                <h1 className='text-3xl font-bold text-white'>Settings</h1>
              </div>
            </header>
          </>
        )}
      </Disclosure>

      <main className='relative -mt-32'>
        <div className='max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8'>
          <div className='bg-white rounded-lg shadow overflow-hidden'>
            <div className='divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x'>
              <aside className='py-6 lg:col-span-3'>
                <nav className='space-y-1'>
                  {subNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      }
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon
                        className={'flex-shrink-0 -ml-1 mr-3 h-6 w-6'}
                        aria-hidden='true'
                      />
                      <span className='truncate'>{item.name}</span>
                    </a>
                  ))}
                </nav>
              </aside>

              <form
                className='divide-y divide-gray-200 lg:col-span-9'
                action='#'
                method='POST'
              >
                {/* Profile section */}
                <div className='py-6 px-4 sm:p-6 lg:pb-8'>
                  <div>
                    <h2 className='text-lg leading-6 font-medium text-gray-900'>
                      Profile
                    </h2>
                    <p className='mt-1 text-sm text-gray-500'>
                      This information will be displayed publicly so be careful
                      what you share.
                    </p>
                  </div>

                  <div className='mt-6 flex flex-col lg:flex-row'>
                    <div className='flex-grow space-y-6'>
                      <div>
                        <label
                          htmlFor='username'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Username
                        </label>
                        <div className='mt-1 rounded-md shadow-sm flex'>
                          <span className='bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm'>
                            workcation.com/
                          </span>
                          <input
                            type='text'
                            name='username'
                            id='username'
                            autoComplete='username'
                            className='focus:ring-sky-500 focus:border-sky-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                            defaultValue={user.handle}
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor='about'
                          className='block text-sm font-medium text-gray-700'
                        >
                          About
                        </label>
                        <div className='mt-1'>
                          <textarea
                            id='about'
                            name='about'
                            rows={3}
                            className='shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                            defaultValue={''}
                          />
                        </div>
                        <p className='mt-2 text-sm text-gray-500'>
                          Brief description for your profile. URLs are
                          hyperlinked.
                        </p>
                      </div>
                    </div>

                    <div className='mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0'>
                      <p
                        className='text-sm font-medium text-gray-700'
                        aria-hidden='true'
                      >
                        Photo
                      </p>
                      <div className='mt-1 lg:hidden'>
                        <div className='flex items-center'>
                          <div
                            className='flex-shrink-0 inline-block rounded-full overflow-hidden h-12 w-12'
                            aria-hidden='true'
                          >
                            <img
                              className='rounded-full h-full w-full'
                              src={user.imageUrl}
                              alt=''
                            />
                          </div>
                          <div className='ml-5 rounded-md shadow-sm'>
                            <div className='group relative border border-gray-300 rounded-md py-2 px-3 flex items-center justify-center hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-sky-500'>
                              <label
                                htmlFor='user-photo'
                                className='relative text-sm leading-4 font-medium text-gray-700 pointer-events-none'
                              >
                                <span>Change</span>
                                <span className='sr-only'> user photo</span>
                              </label>
                              <input
                                id='user-photo'
                                name='user-photo'
                                type='file'
                                className='absolute w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md'
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='hidden relative rounded-full overflow-hidden lg:block'>
                        <img
                          className='relative rounded-full w-40 h-40'
                          src={user.imageUrl}
                          alt=''
                        />
                        <label
                          htmlFor='user-photo'
                          className='absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100'
                        >
                          <span>Change</span>
                          <span className='sr-only'> user photo</span>
                          <input
                            type='file'
                            id='user-photo'
                            name='user-photo'
                            className='absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md'
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='mt-6 grid grid-cols-12 gap-6'>
                    <div className='col-span-12 sm:col-span-6'>
                      <label
                        htmlFor='first-name'
                        className='block text-sm font-medium text-gray-700'
                      >
                        First name
                      </label>
                      <input
                        type='text'
                        name='first-name'
                        id='first-name'
                        autoComplete='given-name'
                        className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm'
                      />
                    </div>

                    <div className='col-span-12 sm:col-span-6'>
                      <label
                        htmlFor='last-name'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Last name
                      </label>
                      <input
                        type='text'
                        name='last-name'
                        id='last-name'
                        autoComplete='family-name'
                        className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm'
                      />
                    </div>

                    <div className='col-span-12'>
                      <label
                        htmlFor='url'
                        className='block text-sm font-medium text-gray-700'
                      >
                        URL
                      </label>
                      <input
                        type='text'
                        name='url'
                        id='url'
                        className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm'
                      />
                    </div>

                    <div className='col-span-12 sm:col-span-6'>
                      <label
                        htmlFor='company'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Company
                      </label>
                      <input
                        type='text'
                        name='company'
                        id='company'
                        autoComplete='organization'
                        className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm'
                      />
                    </div>
                  </div>
                </div>

                {/* Privacy section */}
                <div className='pt-6 divide-y divide-gray-200'>
                  <div className='px-4 sm:px-6'>
                    <div>
                      <h2 className='text-lg leading-6 font-medium text-gray-900'>
                        Privacy
                      </h2>
                      <p className='mt-1 text-sm text-gray-500'>
                        Ornare eu a volutpat eget vulputate. Fringilla commodo
                        amet.
                      </p>
                    </div>
                    <ul className='mt-2 divide-y divide-gray-200'>
                      <Switch.Group
                        as='li'
                        className='py-4 flex items-center justify-between'
                      >
                        <div className='flex flex-col'>
                          <Switch.Label
                            as='p'
                            className='text-sm font-medium text-gray-900'
                            passive
                          >
                            Available to hire
                          </Switch.Label>
                          <Switch.Description className='text-sm text-gray-500'>
                            Nulla amet tempus sit accumsan. Aliquet turpis sed
                            sit lacinia.
                          </Switch.Description>
                        </div>
                        <Switch
                          checked={availableToHire}
                          onChange={setAvailableToHire}
                          className={
                            'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                          }
                        >
                          <span
                            aria-hidden='true'
                            className={
                              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            }
                          />
                        </Switch>
                      </Switch.Group>
                      <Switch.Group
                        as='li'
                        className='py-4 flex items-center justify-between'
                      >
                        <div className='flex flex-col'>
                          <Switch.Label
                            as='p'
                            className='text-sm font-medium text-gray-900'
                            passive
                          >
                            Make account private
                          </Switch.Label>
                          <Switch.Description className='text-sm text-gray-500'>
                            Pharetra morbi dui mi mattis tellus sollicitudin
                            cursus pharetra.
                          </Switch.Description>
                        </div>
                        <Switch
                          checked={privateAccount}
                          onChange={setPrivateAccount}
                          className={
                            'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                          }
                        >
                          <span
                            aria-hidden='true'
                            className={
                              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            }
                          />
                        </Switch>
                      </Switch.Group>
                      <Switch.Group
                        as='li'
                        className='py-4 flex items-center justify-between'
                      >
                        <div className='flex flex-col'>
                          <Switch.Label
                            as='p'
                            className='text-sm font-medium text-gray-900'
                            passive
                          >
                            Allow commenting
                          </Switch.Label>
                          <Switch.Description className='text-sm text-gray-500'>
                            Integer amet, nunc hendrerit adipiscing nam.
                            Elementum ame
                          </Switch.Description>
                        </div>
                        <Switch
                          checked={allowCommenting}
                          onChange={setAllowCommenting}
                          className={
                            'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                          }
                        >
                          <span
                            aria-hidden='true'
                            className={
                              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            }
                          />
                        </Switch>
                      </Switch.Group>
                      <Switch.Group
                        as='li'
                        className='py-4 flex items-center justify-between'
                      >
                        <div className='flex flex-col'>
                          <Switch.Label
                            as='p'
                            className='text-sm font-medium text-gray-900'
                            passive
                          >
                            Allow mentions
                          </Switch.Label>
                          <Switch.Description className='text-sm text-gray-500'>
                            Adipiscing est venenatis enim molestie commodo eu
                            gravid
                          </Switch.Description>
                        </div>
                        <Switch
                          checked={allowMentions}
                          onChange={setAllowMentions}
                          className={
                            'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                          }
                        >
                          <span
                            aria-hidden='true'
                            className={
                              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            }
                          />
                        </Switch>
                      </Switch.Group>
                    </ul>
                  </div>
                  <div className='mt-4 py-4 px-4 flex justify-end sm:px-6'>
                    <button
                      type='button'
                      className='bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='ml-5 bg-sky-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
