import './Tasks.css';

export const Tasks = () => {
  const tasks = [
    {
      name: 'Fullstack',
      started: '01.01.2022',
      ends: '01.02.2022',
      status: 'Active',
    },
    {
      name: 'Frontend',
      started: '20.01.2022',
      ends: '20.02.2022',
      status: 'Active',
    },
  ];

  return (
    <div className='py-4'>
      <div className='top'>
        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5'>
          <h1 className='text-2xl font-semibold text-gray-900'>Tasks</h1>

          <div className='max-w-lg flex rounded-md shadow-sm'></div>

          <div className='max-w-lg  rounded-md  flex justify-end'>
            <div className='buttonsDesk'>
              <button
                type='submit'
                className='newTask__button inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                New task
              </button>

              <div className='max-w-lg flex rounded-md shadow-sm'>
                <select
                  id='status'
                  name='status'
                  className='desktopSelect block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
                  defaultValue='Active'
                >
                  <option>Active</option>
                  <option>Offline</option>
                  <option>Error</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className='mobSelectCont'>
          <select
            id='status'
            name='status'
            className='block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
            defaultValue='Active'
          >
            <option>Active</option>
            <option>Offline</option>
            <option>Error</option>
          </select>
        </div>
      </div>

      <div className='px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-2'>
        <div className='flex flex-col'>
          <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='align-middle inline-block min-w-full sm:px-6'>
              <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg desktop'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                      >
                        Task name
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                      >
                        Started
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                      >
                        Ends
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {tasks.map((item) => (
                      <tr key={item.name}>
                        <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                          {item.name}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                          {item.started}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                          {item.ends}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap  text-right text-gray-500'>
                          <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800'>
                            {item.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mobile'>
                {tasks.map((item) => (
                  <div className='tasksCont' key={item.name}>
                    <div className='column'>
                      <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800'>
                        {item.status}
                      </span>
                    </div>
                    <div className='column'>
                      <div className='description'>
                        <p className='px-6  whitespace-nowrap text-sm font-medium text-gray-900'>
                          {item.name}
                        </p>
                        <div className='timeLine'>
                          <p className='px-6  whitespace-nowrap text-sm text-gray-500'>
                            {item.started} - {item.ends}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
