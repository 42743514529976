import { Link } from 'react-router-dom';

const AuthLinks = () => {
  return (
    <>
      <Link
        to={'/auth'}
        className='block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100'
      >
        Log in
      </Link>
      <Link
        to={'/reg'}
        className='block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100'
      >
        Sign up
      </Link>
    </>
  );
};

export default AuthLinks;
