import axios, { AxiosError } from 'axios';
import { ErrorDataType } from './RTKService/types/Api.types';
import { API_ADDRESS, WS_ADDRESS } from '../config/const';
import { getStorageItem } from './localStorageService/localStorageService';
import { authThunk } from '../redux/slices/authSlice';

const baseURL = `${API_ADDRESS}/api`;
const wsBaseURL = `${WS_ADDRESS}/ws`;

export const notAuthedInstance = axios.create({
  baseURL,
});

export const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  function (config: any) {
    const token = getStorageItem('token');
    (token || token === '') &&
      (config.headers.authorization = `Token ${token}`);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (config) => config,
  async (error: AxiosError<ErrorDataType>) => {
    const status = error.response?.status || null;

    const description_for_devs =
      error.response?.data.description_for_devs || '';

    if (description_for_devs === 'Can`t authorize via cookies') {
      error.response
        ? (error.response.data.description_for_users =
            'Выполните вход на hh.ru')
        : undefined;
    }

    const logoutCases = [
      'Invalid authentication. Could not decode token.',
      'Signature has expired',
    ];

    if (
      logoutCases.includes(description_for_devs) ||
      status === 403 ||
      status === 401
    ) {
      const { store } = await import('../redux/store');
      store.dispatch(authThunk.logout());
    }
    return Promise.reject(error);
  },
);

export class WSInstance extends WebSocket {
  constructor(url: string) {
    const token = getStorageItem('token');

    if (!token || token === '') {
      throw new Error('User is not authed.');
    }

    super(`${wsBaseURL}${url}?${token}`);
  }
}
