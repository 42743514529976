import './SignUp.css';
import { SignLayout } from '../../Components';
import { useSignUp } from './useSignUp';

export const SignUp = () => {
  const { errors, isLoading, onLink, onSubmit, control } = useSignUp();

  return (
    <SignLayout
      isLoading={isLoading}
      onSubmit={onSubmit}
      control={control}
      heading={'Регистрация'}
      onLink={onLink}
      errors={errors}
      linkName={'Вход'}
    />
  );
};
