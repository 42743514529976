// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-card {
  width: 500px;
}

@media screen and (max-width: 600px) {
  .form-card {
    max-width: 90vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/SignIn/SignIn.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".form-card {\n  width: 500px;\n}\n\n@media screen and (max-width: 600px) {\n  .form-card {\n    max-width: 90vw;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
