import { TextareaHTMLAttributes } from 'react';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';
import { Textarea } from './Textarea';
import cn from 'classnames';

interface IHookFormControlledTextareaProps<T extends FieldValues>
  extends Omit<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    'onChange' | 'value' | 'onBlur'
  > {
  control: Control<T>;
  name: FieldPath<T>;
  rules?: Omit<
    RegisterOptions<T, FieldPath<T>>,
    'valueAsNumber' | 'valueAsDate' | 'disabled'
  >;
  showError?: boolean;
}

export const HookFormControlledTextarea = <T extends Record<string, any>>(
  props: IHookFormControlledTextareaProps<T>,
) => {
  const { showError = false, control, rules, name, ...rest } = props;

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <div className={'relative flex flex-col mb-2'}>
          <div className={cn('border rounded', error && 'border-red-500')}>
            {rules?.required && (
              <div
                className={
                  'absolute -top-2 -right-3 text-red-500 text-xl font-bold'
                }
              >
                *
              </div>
            )}
            <Textarea
              value={(value || '').toString()}
              onChange={onChange}
              onBlur={onBlur}
              {...rest}
            />
          </div>
          {error && showError ? (
            <div className={'absolute top-32 left-2 text-sm text-red-500'}>
              {error.message}
            </div>
          ) : null}
        </div>
      )}
    />
  );
};
