import React, { memo, PropsWithChildren } from 'react';

interface IInputWrapperProps {
  label: string;
}

export const InputWrapper: React.FC<PropsWithChildren<IInputWrapperProps>> =
  memo(({ children, label }) => {
    return (
      <div className='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
        <label className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
          {label}
        </label>
        <div>{children}</div>
      </div>
    );
  });
