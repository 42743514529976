import whiteLogo from '../../assets/svg/whiteLogo.svg';
import { NavLink } from 'react-router-dom';

export const Logo = () => {
  return (
    <NavLink
      className={'flex-shrink-0 flex items-center px-4'}
      to={'/dashboard/statistics'}
    >
      <img className='h-8 w-auto' src={whiteLogo} alt='logo' />
    </NavLink>
  );
};
