import React, { memo, useCallback } from 'react';
import Select, { OnChangeValue, Options } from 'react-select';
import makeAnimated from 'react-select/animated';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';

const animatedComponents = makeAnimated();

export interface ISelectOption {
  value: string;
  label: string;
}

export interface ISelectProps extends StateManagerProps {
  options?: Options<ISelectOption>;
  value: string | string[];
  onChange: (...event: any[]) => void;
}

export const ReactSelector: React.FC<ISelectProps> = memo(
  ({ options = [], value = '', onChange, isMulti, ...rest }: ISelectProps) => {
    const onChangeHandler = useCallback(
      (newValue: OnChangeValue<ISelectOption, boolean>) => {
        onChange(
          isMulti
            ? (newValue as ISelectOption[]).map(
                (item: ISelectOption) => item.value,
              )
            : (newValue as ISelectOption).value,
        );
      },
      [isMulti, onChange],
    );

    const getValue = useCallback(() => {
      if (value) {
        return isMulti
          ? options.filter((option) => value.indexOf(option.value) >= 0)
          : options.find((option) => option.value === value);
      } else {
        return isMulti ? [] : ('' as any);
      }
    }, [value, isMulti, options]);

    return (
      <Select
        classNamePrefix='custom-select'
        value={getValue()}
        options={options}
        onChange={onChangeHandler}
        isMulti={isMulti}
        components={animatedComponents}
        {...rest}
      />
    );
  },
);
