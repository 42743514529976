// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-page__main {
  min-height: 100vh;
}

.button_blocked {
  opacity: 0.5;
  cursor: not-allowed;
}

.outer-routes {
  display: flex;
  justify-content: center;
  align-items: center;
}

.outer-routes__wrapper {
  padding: 0;
}

/* ---> Remove arrows in inputs type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
/* Remove arrows in inputs type number <--- */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA,6CAA6C;AAC7C,gCAAgC;AAChC;;IAEI,wBAAwB;IACxB,SAAS;AACb;;AAEA,YAAY;AACZ;IACI,0BAA0B;AAC9B;AACA,6CAA6C","sourcesContent":[".landing-page__main {\n  min-height: 100vh;\n}\n\n.button_blocked {\n  opacity: 0.5;\n  cursor: not-allowed;\n}\n\n.outer-routes {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.outer-routes__wrapper {\n  padding: 0;\n}\n\n/* ---> Remove arrows in inputs type number */\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\n/* Firefox */\ninput[type=number] {\n    -moz-appearance: textfield;\n}\n/* Remove arrows in inputs type number <--- */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
