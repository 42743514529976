// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blockingWrapper {
  position: relative;
}

.blockingWrapperBlock {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(243, 244, 246, .5);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
}`, "",{"version":3,"sources":["webpack://./src/Components/BlockingWrapper/BlockingWrapper.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yCAAyC;EACzC,kCAA0B;UAA1B,0BAA0B;AAC5B","sourcesContent":[".blockingWrapper {\n  position: relative;\n}\n\n.blockingWrapperBlock {\n  position: absolute;\n  top: -5px;\n  left: -5px;\n  width: 100%;\n  height: 100%;\n  padding: 10px;\n  box-sizing: content-box;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(243, 244, 246, .5);\n  backdrop-filter: blur(1px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
