import React, { memo } from 'react';

import { ReactSelector } from '../../../../../Components';

interface IRegionSelectorProps {
  region: string;
  onChange: (...event: any[]) => void;
}

export const RegionSelector: React.FC<IRegionSelectorProps> = memo(
  ({ region, onChange }) => {
    const options = [{ value: '', label: '' }];

    return (
      <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
        <label
          htmlFor='region'
          className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
        >
          Регион
        </label>
        <div className='max-w-lg flex rounded-md shadow-sm'>
          <ReactSelector
            defaultValue={options[0].value}
            isMulti={false}
            options={options}
            value={region}
            onChange={onChange}
            className={
              'w-full flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 block py-1 text-base rounded-md'
            }
            placeholder={'Название региона'}
          />
        </div>
      </div>
    );
  },
);
