import { memo } from 'react';

export const Description = memo(() => {
  return (
    <ol>
      <li className='font-semibold text-gray-500'>
        - Для авторизации необходимо установить расширение
        <a
          className={'text-indigo-600 ml-1 hover:underline'}
          href={
            'https://chrome.google.com/webstore/detail/hhmailer-authorizaion/nomccogeegidhfhjljnkdbdfmgeegfji?hl=ru'
          }
        >
          HHmailer authorizaion
        </a>
      </li>
      <li className='font-semibold text-gray-500'>
        - Авторизоваться на сайте
        <a
          className={'text-indigo-600 ml-1 hover:underline'}
          href={'https://hh.ru'}
        >
          HH.ru
        </a>
      </li>
      <li className='font-semibold text-gray-500'>
        - После авторизации на
        <a
          className={'text-indigo-600 ml-1 hover:underline'}
          href={'https://hh.ru'}
        >
          HH.ru
        </a>
        , расширение
        <a
          className={'text-indigo-600 ml-1 mr-1 hover:underline'}
          href={
            'https://chrome.google.com/webstore/detail/hhmailer-authorizaion/nomccogeegidhfhjljnkdbdfmgeegfji?hl=ru'
          }
        >
          HHmailer authorizaion
        </a>
        получит доступ к Cookie и сохранит их в буфер обмена
      </li>
      <li className='font-semibold text-gray-500'>
        - Вставьте Cookie в поле из буфера обмена и нажмите на кнопку
        авторизоваться
      </li>
    </ol>
  );
});
