import React, { DetailedHTMLProps, memo } from 'react';
import cn from 'classnames';

import './Input.css';

type IInputProps = DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const Input: React.FC<IInputProps> = memo(({ className, ...rest }) => {
  return (
    <input
      className={cn(
        'form__input block w-full border border-gray-300 transition-colors rounded-md shadow-sm py-2 px-3 hover:border-gray-400 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm',
        className,
      )}
      {...rest}
    />
  );
});
