// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
    top: 0;
    left: 0;
    transform: translateX(-100%);
    z-index: 10;
    position: fixed;
    width: 100%;
    transition: .3s all;
}

.menu.active {
    transform: translateX(0);
}`, "",{"version":3,"sources":["webpack://./src/Components/Mobilemenu/Menu.css"],"names":[],"mappings":"AAAA;IACI,MAAM;IACN,OAAO;IACP,4BAA4B;IAC5B,WAAW;IACX,eAAe;IACf,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".menu {\n    top: 0;\n    left: 0;\n    transform: translateX(-100%);\n    z-index: 10;\n    position: fixed;\n    width: 100%;\n    transition: .3s all;\n}\n\n.menu.active {\n    transform: translateX(0);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
